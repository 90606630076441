import { useSnackbar } from 'baseui/snackbar';
import { Save } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';

import CustomerForm from './features/CustomerForm';

import MuatationBanner from 'components/Banner/MutationBanner';
import { Button } from 'components/Button';
import { QueryResolver } from 'components/QueryResolver';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useCustomerQuery,
  useEditCustomerMutation,
} from 'services/api/customers/endpoints';
import { useLocale } from 'utils/hooks/useLocale';

export default function EditCustomer() {
  const { t } = useLocale();
  const navigate = useNavigate();
  const params = useParams();
  const query = useCustomerQuery(Number(params.customerId));
  const [editCustomer, mutationState] = useEditCustomerMutation();
  const snackbar = useSnackbar();

  return (
    <QueryResolver query={query}>
      {(customer) => (
        <div className="space-y-10">
          <DashboardContentHeader title={t('Edit Customer')} description="" />
          <MuatationBanner
            show={mutationState.isError}
            message={t('customer_update_error')}
          />
          <CustomerForm
            id="edit-customer-form"
            initialValue={{
              ...customer,
              address: { isVerified: true, ...customer.address },
              isCompany: customer.firstName === customer.businessName,
              businessName: customer.businessName ?? '',
            }}
            onSubmit={async (data) => {
              const { billingAddress, ...customerData } = data;
              const result = await editCustomer({
                ...customer,
                id: customer.id,
                contact: '',
                ...customerData,
                lastName: customerData.lastName ?? '',
                address: {
                  id: customer.address.id,
                  ...customerData.address,
                },
              });
              if (isMutationSuccess(result)) {
                snackbar.enqueue({
                  message: t('customer_update_successfully'),
                });
                navigate(`/fs-admin/customers?customerId=${customer.id}`);
              }
            }}
          />
          <div className="flex justify-end">
            <Button
              kind="tertiary"
              onClick={() => navigate('/fs-admin/customers')}
              disabled={mutationState.isLoading}
            >
              {t('Cancel')}
            </Button>
            <Button
              form="edit-customer-form"
              type="submit"
              startEnhancer={<Save />}
              size="compact"
              isLoading={mutationState.isLoading}
            >
              {t('Update Customer')}
            </Button>
          </div>
        </div>
      )}
    </QueryResolver>
  );
}
