import { StyledAction, StyledBody, StyledTitle } from 'baseui/card';
import { Skeleton } from 'baseui/skeleton';
import { Spinner } from 'baseui/spinner';
import { Tag } from 'baseui/tag';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { MutableRefObject, useMemo, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  Dialog,
  Heading,
  Button,
} from 'react-aria-components';
import {
  DollarSign,
  Edit,
  Mail,
  Navigation,
  Phone,
  Plus,
  Search,
  X,
  Paperclip,
} from 'react-feather';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import CustomersUploadedCsvIndex from './customers-uploaded-csv-index';

import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { LinkButton } from 'components/LinkButton';
import { Pagination } from 'components/Pagination';
import { QueryResolver } from 'components/QueryResolver';
import { createTableBuilder } from 'components/Table';
import { Button as UIButton } from 'components/ui/button';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { WORK_REQUEST_STATUS_VALUES } from 'services/api/constants';
import {
  useCustomerQuery,
  useCustomersQuery,
  useUploadCsvMutation,
} from 'services/api/customers/endpoints';
import { Customer, CustomerQueryParams } from 'services/api/customers/types';
import { formatAddress } from 'services/api/helpers';
import { useInvoicesQuery } from 'services/api/invoices/endpoints';
import type { Invoice } from 'services/api/invoices/types';
import { formatDate, priceFormatter, cn } from 'utils/helpers';
import useDebounce from 'utils/hooks/useDebounce';
import { useLocale } from 'utils/hooks/useLocale';

const useValidatedSearchParams = <Schema extends z.ZodTypeAny>(
  schema: Schema,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return {
    searchParams,
    setSearchParams,
    values: schema.parse(Object.fromEntries(searchParams.entries())) as z.infer<
      typeof schema
    >,
  };
};

const isInvoiceOverdue = (invoice: Invoice): boolean => {
  if (invoice.invoiceDue === null) return false;

  return differenceInDays(Date.now(), new Date(invoice.invoiceDue)) >= 14;
};

const hasOverdueInvoice = (
  customer: Customer,
  invoices: Invoice[],
): boolean => {
  return (
    invoices
      .filter((invoice) => invoice.customer === customer.id)
      .filter(isInvoiceOverdue).length !== 0
  );
};

export default function Customers() {
  const { t } = useLocale();
  return (
    <div className="flex h-screen flex-1 flex-col space-y-5 overflow-hidden">
      <DashboardContentHeader
        title={t('Customer')}
        description={t('Track and manage your Customers')}
      />
      <div className="flex h-full flex-1 space-x-4 overflow-hidden">
        <div className="w-80 flex-shrink-0">
          <BasicDetails />
        </div>
        <section className="flex-1 space-y-5 overflow-auto">
          <CustomersTable />
        </section>
      </div>
    </div>
  );
}
const { Table, Column } = createTableBuilder<Customer>();

const INVOICE_SEVERITY_VALUES = {
  DUE: 'DUE',
  OVERDUE: 'OVERDUE',
} as const;
const customerPageSearchParamsSchema = z.object({
  status: z.nativeEnum(WORK_REQUEST_STATUS_VALUES).optional().catch(undefined),
  severity: z.preprocess(
    (v) => String(v).toUpperCase(),
    z.nativeEnum(INVOICE_SEVERITY_VALUES).optional().catch(undefined),
  ),
  search: z.string().catch(''),
  page: z.coerce.number().catch(1),
});

function CustomersTable(params: CustomerQueryParams) {
  const { t } = useLocale();
  const {
    searchParams,
    setSearchParams,
    values: validatedSearchParams,
  } = useValidatedSearchParams(customerPageSearchParamsSchema);
  const { page, severity, search, status } = validatedSearchParams;

  const [searchQuery, setSearchQuery] = useState(search);
  const debouncedSearch = useDebounce(searchQuery, 300);
  const hasSelectedFilters = !!searchQuery || !!status || !!severity;

  const [uploadCSV, mutation] = useUploadCsvMutation();
  const isLoading = mutation.isLoading;
  const [isOpen, setIsOpen] = useState(false);
  const [isFileTypeError, setIsFileTypeError] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const fileUploadRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const isCsvFile = (file: File) => {
    return file.type === 'text/csv' || file.name.endsWith('.csv');
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (isCsvFile(selectedFile)) {
        setFile(selectedFile);
      } else {
        setIsFileTypeError(true);
      }
    }
  };

  const handleCsvDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files) {
      const droppedFile = event.dataTransfer.files[0];
      if (isCsvFile(droppedFile)) {
        setFile(droppedFile);
      } else {
        setIsFileTypeError(true);
      }
    }
  };

  const handleCsvUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('csvFile', file);

    try {
      await uploadCSV(formData).unwrap();
      setIsFileTypeError(false);
      setFile(null);
      if (fileUploadRef.current) {
        fileUploadRef.current.value = '';
      }
      setIsSuccessModalOpen(true);
    } catch (error) {
      setIsUploadFailed(true);
    }
  };

  const handleRemoveCsvFile = () => {
    setFile(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
  };

  const customerTotalCountQuery = useCustomersQuery(
    { params },
    { refetchOnMountOrArgChange: true },
  ).data?.totalElements;

  const customersQuery = useCustomersQuery(
    {
      params: {
        limit: 20,
        search: debouncedSearch,
        page,
        'work-request-status': status,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const invoiceQuery = useInvoicesQuery(
    {
      params: {
        workRequestStatus: status,
        limit: 2000,
      },
    },
    { skip: severity === undefined },
  );

  const data = useMemo(() => {
    const invoices = invoiceQuery.data?.list ?? [];
    return (
      customersQuery.data?.list.filter((customer) =>
        severity === 'OVERDUE' ? hasOverdueInvoice(customer, invoices) : true,
      ) ?? []
    );
  }, [customersQuery, invoiceQuery, severity]);

  function removeStatusFilter() {
    searchParams.delete('status');
    searchParams.delete('severity');
    setSearchParams(searchParams);
  }

  function statusFilterTagStyle() {
    switch (severity) {
      case 'DUE':
        return 'warning';
      case 'OVERDUE':
        return 'negative';
      default:
        return 'warning';
    }
  }

  function convertStatusWording() {
    switch (severity) {
      case 'DUE':
        return (
          <div className="flex items-center py-2 text-white">
            <DollarSign className="mr-2" size={16} />
            {t('Due')}
          </div>
        );

      case 'OVERDUE':
        return (
          <div className="flex items-center py-2 text-white">
            <DollarSign className="mr-2" size={16} />
            {t('Past Due')}
          </div>
        );
      default:
        break;
    }
  }

  const rowIdSearchParams = (id: number) => {
    searchParams.set('customerId', id.toString());
    return searchParams.toString();
  };
  return (
    <Card>
      <StyledTitle $as="div">
        {status && convertStatusWording() !== undefined && (
          <Tag
            onActionClick={removeStatusFilter}
            overrides={{
              Root: {
                props: {
                  className: clsx('rounded-md border-none py-4', {
                    'bg-[#FFC42E]': severity === 'DUE',
                    'bg-[#FF3030]': severity === 'OVERDUE',
                  }),
                },
              },
              Text: { props: { className: 'max-w-none' } },
              ActionIcon: { props: { className: 'text-white' } },
            }}
            kind={statusFilterTagStyle()}
          >
            {convertStatusWording()}
          </Tag>
        )}
        <div className="gap-4 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {customerTotalCountQuery} {t('Customers')}
            </h1>
          </div>
          <nav className="flex items-center">
            <UIButton
              startEnhancer={<Plus />}
              className="shrink-0"
              onClick={() => setIsOpen(true)}
            >
              {t('Upload CSV')}
            </UIButton>
          </nav>

          <ModalOverlay
            className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/50 py-3 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0"
            isOpen={isOpen}
            onOpenChange={setIsOpen}
            isDismissable
          >
            <Modal className="relative z-50 grid w-full max-w-5xl gap-4 rounded-2xl border bg-white p-6 shadow-lg duration-200 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95 md:w-full">
              <Dialog className="outline-none">
                {(props) => (
                  <>
                    <CloseButton {...props} />
                    <Heading
                      slot="title"
                      className="text-lg font-semibold leading-none tracking-tight"
                    >
                      {t('Upload File')}
                    </Heading>
                    <div
                      className={`m-3 flex justify-center p-20 text-center ${
                        !file
                          ? 'rounded-lg border-2 border-dashed border-gray-300'
                          : ''
                      }`}
                      onDragOver={(event) => {
                        event.preventDefault();
                      }}
                      onDrop={handleCsvDrop}
                    >
                      <input
                        type="file"
                        className="hidden"
                        ref={fileUploadRef}
                        onChange={handleFileChange}
                        accept=".csv"
                      />
                      {file ? (
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center gap-2">
                            <Paperclip size={16} />
                            {file.name}
                            <span>
                              ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                            </span>
                          </div>
                          <Button type="button" onPress={handleRemoveCsvFile}>
                            <X />
                          </Button>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center gap-2">
                          <Heading>{t('Drag File to Upload')}</Heading>
                          <Button
                            onPress={() => {
                              fileUploadRef.current?.click();
                            }}
                            className="text-brand-primary underline hover:text-brand-primary-700"
                          >
                            <Heading> {t('Upload CSV')}</Heading>
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div className="mt-2 flex justify-start space-x-2">
                        <Button
                          type="button"
                          className={cn([
                            'inline-flex items-center rounded-md bg-brand-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-primary-600',
                            // Disabled
                            'data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed',
                          ])}
                        >
                          <a
                            href="/sample-template.csv"
                            download="sample-template.csv"
                          >
                            {t('Download CSV Template')}
                          </a>
                        </Button>
                      </div>
                      <div className="mt-2 flex justify-end space-x-2">
                        <Button
                          type="button"
                          className={cn([
                            'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto',
                            // Disabled
                            'data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed',
                          ])}
                          onPress={() => setIsOpen(false)}
                          isDisabled={isLoading}
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          type="submit"
                          onPress={handleCsvUpload}
                          className={cn([
                            'inline-flex items-center rounded-md bg-brand-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-primary-600',
                            // Disabled
                            'data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed',
                          ])}
                          isDisabled={!file || isLoading}
                        >
                          {isLoading && (
                            <Spinner
                              className="mr-2"
                              $size={16}
                              $borderWidth={2}
                            />
                          )}
                          {t('Upload')}
                        </Button>
                      </div>
                    </div>

                    <CustomersUploadedCsvIndex />
                  </>
                )}
              </Dialog>
            </Modal>
          </ModalOverlay>

          <ModalOverlay
            className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/50 py-3 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0"
            isOpen={isUploadFailed}
            onOpenChange={setIsUploadFailed}
            isDismissable
          >
            <Modal className="relative z-50 w-full max-w-md rounded-2xl border bg-white p-6 shadow-lg duration-200 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95">
              <Dialog className="outline-none">
                <div className="my-6 p-3 text-center text-gray-500">
                  <div>
                    {/* Upload failed. Please try again. */}
                    <Heading>{t('customer_csv_upload_error')}</Heading>
                    {/* Maximum file size is 10 MB */}
                    <Heading>
                      {t('customer_csv_upload_maximum_file_size')}
                    </Heading>
                  </div>
                </div>
                <div className="mt-2 flex justify-end space-x-2">
                  <Button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-brand-primary px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-brand-primary hover:bg-brand-primary-700 sm:w-auto"
                    onPress={() => setIsUploadFailed(false)}
                  >
                    {t('Confirm')}
                  </Button>
                </div>
              </Dialog>
            </Modal>
          </ModalOverlay>

          <ModalOverlay
            className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/50 py-3 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0"
            isOpen={isSuccessModalOpen}
            onOpenChange={setIsSuccessModalOpen}
            isDismissable
          >
            <Modal className="relative z-50 w-full max-w-md rounded-2xl border bg-white p-6 shadow-lg duration-200 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95">
              <Dialog className="outline-none">
                <div className="my-6 p-3 text-center text-gray-500">
                  {/* Your file has been uploaded successfully */}
                  {t('customer_csv_upload_success')}
                </div>
                <div className="mt-2 flex justify-end space-x-2">
                  <Button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-brand-primary px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-brand-primary hover:bg-brand-primary-700 sm:w-auto"
                    onPress={() => setIsSuccessModalOpen(false)}
                  >
                    {t('Confirm')}
                  </Button>
                </div>
              </Dialog>
            </Modal>
          </ModalOverlay>

          <ModalOverlay
            className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/50 py-3 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0"
            isOpen={isFileTypeError}
            onOpenChange={setIsFileTypeError}
            isDismissable
          >
            <Modal className="relative z-50 grid w-full max-w-md gap-4 rounded-2xl border bg-white p-6 shadow-lg duration-200 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95 md:w-full">
              <Dialog className="outline-none">
                {(props) => (
                  <>
                    <CloseButton {...props} />
                    <Heading
                      slot="title"
                      className="text-lg font-semibold leading-none tracking-tight"
                    >
                      {t('File Error')}
                    </Heading>
                    <div className="my-6 text-gray-500">
                      {/* File should be CSV Type */}
                      {t('customer_csv_file_type_error')}
                    </div>
                    <div className="mt-2 flex justify-end space-x-2">
                      <Button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-brand-primary px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-brand-primary hover:bg-brand-primary-700 sm:w-auto"
                        onPress={() => setIsFileTypeError(false)}
                      >
                        {t('Confirm')}
                      </Button>
                    </div>
                  </>
                )}
              </Dialog>
            </Modal>
          </ModalOverlay>

          <nav className="flex items-center">
            <UIButton href="new" startEnhancer={<Plus />} className="shrink-0">
              {t('New Customer')}
            </UIButton>
          </nav>
          <div className="flex items-center space-x-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Input
                placeholder={t('Search')}
                endEnhancer={<Search size={16} />}
                onChange={(e) => {
                  searchParams.delete('page');
                  setSearchQuery(e.target.value);
                }}
                onClear={() => searchParams.delete('page')}
                value={searchQuery}
                clearable
              />
            </form>
          </div>
        </div>
      </StyledTitle>
      <StyledBody>
        <Table
          data={data}
          isLoading={customersQuery.isLoading || customersQuery.isFetching}
          loadingMessage={<Skeleton rows={4} height="100px" />}
          isError={customersQuery.isError}
          isEmpty={customersQuery.isSuccess && data.length === 0}
          // emptyMessage={
          //   customersQuery.isError ? (
          //     <div className="bg-white shadow sm:rounded-lg">
          //       <div className="px-4 py-5 sm:p-6">
          //         <h3 className="text-lg font-medium leading-6 text-gray-900">
          //           {t('Failed to get customers')}
          //         </h3>
          //         <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          //           <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          //             <Button size="compact" onClick={customersQuery.refetch}>
          //               {t('Try Again')}
          //             </Button>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   ) : (
          //     <div className="text-center">
          //       <Users className="mx-auto h-12 w-12 text-gray-400" />
          //       <h3 className="mt-2 text-sm font-medium text-gray-900">
          //         {hasSelectedFilters ? 'No customers found' : 'No customers'}
          //       </h3>
          //       {!hasSelectedFilters && (
          //         <>
          //           <p className="mt-1 text-sm text-gray-500">
          //             {t('Get started by creating a customer')}.
          //           </p>
          //           <div className="mt-6">
          //             <LinkButton
          //               to="new"
          //               type="button"
          //               size="compact"
          //               startEnhancer={<Plus />}
          //             >
          //               {t('New Customer')}
          //             </LinkButton>
          //           </div>
          //         </>
          //       )}
          //     </div>
          //   )
          // }
        >
          <Column header={t('ID#')}>
            {(row) => (
              <Link
                to={{ search: rowIdSearchParams(row.id) }}
                className="hover:text-brand-primary hover:underline"
              >
                <span aria-hidden="true" className="absolute inset-0" />
                {row.id}
              </Link>
            )}
          </Column>
          <Column header={t('Customer')}>
            {(row) => (
              <span className="whitespace-nowrap">
                {row.firstName} {row.lastName}
              </span>
            )}
          </Column>
          <Column header={t('Company')}>
            {(row) => row.businessName ?? '-'}
          </Column>
          <Column header={t('Email Address')}>{(row) => row.email}</Column>
          <Column header={t('Phone Number')}>
            {(row) => <span className="whitespace-nowrap">{row.phone}</span>}
          </Column>
          <Column header={t('Address')}>
            {(row) => (
              <span className="line-clamp-1" title={formatAddress(row.address)}>
                {formatAddress(row.address)}
              </span>
            )}
          </Column>
          <Column header={t('Date Created')}>
            {(row) => (
              <span className="whitespace-nowrap">
                {formatDate(new Date(row.dateCreated))}
              </span>
            )}
          </Column>
        </Table>
      </StyledBody>
      <StyledAction className="flex justify-end">
        <Pagination
          totalItems={customersQuery.data?.totalElements}
          pageSize={20}
        />
      </StyledAction>
    </Card>
  );
}

function CloseButton(props: { close: VoidFunction }) {
  const { t } = useLocale();
  return (
    <Button
      onPress={props.close}
      className="focus:ring-ring absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none data-[entering]:bg-accent data-[entering]:text-muted-foreground"
    >
      <X className="h-4 w-4" />
      <span className="sr-only">{t('Close')}</span>
    </Button>
  );
}

const basicDetailsSearchParams = z.object({
  customerId: z.coerce.number().catch(0),
});

function BasicDetails() {
  const { t } = useLocale();
  const searchParams = useValidatedSearchParams(basicDetailsSearchParams);
  const customerId = searchParams.values.customerId;
  const customerQuery = useCustomerQuery(Number(customerId), {
    skip: customerId === 0,
  });

  const invoiceQuery = useInvoicesQuery({
    params: {
      customerId,
      limit: 2000,
      workRequestStatus: 'InvoiceSent',
    },
  });

  return (
    <aside className="h-full overflow-hidden">
      <Card
        overrides={{
          Root: {
            props: {
              className: 'h-full',
            },
          },
          Body: {
            props: {
              className: 'flex h-full flex-col justify-between',
            },
          },
          Contents: {
            props: {
              className: 'h-full',
            },
          },
        }}
      >
        <QueryResolver
          query={customerQuery}
          // errorElement={
          //   <div className="flex h-full flex-col items-center justify-center">
          //     {t('Failed to get customer details')},
          //     <Button
          //       size="mini"
          //       kind="tertiary"
          //       onClick={customerQuery.refetch}
          //     >
          //       {t('Try Again')}.
          //     </Button>
          //   </div>
          // }
          uninitializedElement={
            <div className="flex h-full flex-col items-center justify-center">
              <h3 className="mt-2 text-base font-semibold text-gray-900">
                {t('No Customer Selected')}.
              </h3>
              <p className="mt-1 text-center text-base text-gray-500">
                {t('customer_preview_description')}.
              </p>
            </div>
          }
        >
          {(customer) => (
            <>
              <div className="space-y-6">
                <nav className="flex items-center justify-between">
                  <h2 className="div-xl font-semibold">
                    {t('Customer Details')}
                  </h2>
                  <LinkButton
                    to={`${customer.id}/edit`}
                    title={t('Edit')}
                    size="mini"
                    kind="secondary"
                  >
                    <Edit size={12} />
                  </LinkButton>
                </nav>
                <Outlet />
                <div>
                  <span className="text-3xl font-semibold text-brand-primary">
                    #{customer.id}
                  </span>
                </div>

                <dl className="flex flex-1 flex-col space-y-6 overflow-y-auto font-prompt">
                  <div>
                    <div className="text-lg font-semibold ">
                      {t('Customer Details')}
                    </div>
                    <dd>
                      <dl className="space-y-1">
                        <div className="flex items-center">
                          <dt className="sr-only">{t('Customer Name')}</dt>
                          <dd>
                            <div className="text-base font-semibold text-gray-500">
                              {customer.firstName} {customer.lastName}
                            </div>
                            {customer.businessName && (
                              <div className="text-sm text-gray-500">
                                {customer.businessName}
                              </div>
                            )}
                          </dd>
                        </div>
                        <div className="flex items-start space-x-4">
                          <span className="pt-1.5 text-base">
                            <Navigation size={16} />
                          </span>
                          <address className="text-base text-gray-500">
                            {customer.address.street} {customer.address.street2}
                            , {customer.address.city}, {customer.address.state},{' '}
                            {customer.address.country}
                          </address>
                        </div>
                        <div className="flex space-x-4 ">
                          <span className="pt-1.5 text-base">
                            <Phone size={16} />
                          </span>
                          <div className="text-base text-gray-500">
                            {customer.phone}
                          </div>
                        </div>
                        <div className="flex space-x-4 ">
                          <span className="pt-1.5 text-base">
                            <Mail size={16} />
                          </span>
                          <div className="text-base text-gray-500">
                            {customer.email}
                          </div>
                        </div>
                      </dl>
                    </dd>
                  </div>
                  <QueryResolver query={invoiceQuery}>
                    {(invoices) => (
                      <div>
                        <div className="text-lg font-semibold ">
                          {t('Balance')}
                        </div>
                        <dd>
                          {invoices.list.reduce(
                            (all, current) => all + current.invoiceTotal,
                            0,
                          ) !== 0 ? (
                            priceFormatter().format(
                              invoices.list.reduce(
                                (all, current) => all + current.invoiceTotal,
                                0,
                              ),
                            )
                          ) : (
                            <div className="text-brand-primary-50">
                              {t('No current balance')}
                            </div>
                          )}
                        </dd>
                      </div>
                    )}
                  </QueryResolver>
                  {/* <div>
                  <div className="text-lg font-semibold ">Payment Methods</div>
                  <dd>
                    <div className="text-brand-primary-50">
                      No current balance
                    </div>
                  </dd>
                </div> */}
                </dl>
              </div>
              <nav className="mt-auto flex justify-start">
                <LinkButton to={`${customerId}`} kind="tertiary" size="compact">
                  {t('See more details')}....
                </LinkButton>
              </nav>
            </>
          )}
        </QueryResolver>
      </Card>
    </aside>
  );
}
