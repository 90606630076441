import { useEffect } from 'react';
import { Heading } from 'react-aria-components';
import {
  Edit3 as EditIcon,
  Image as ImageIcon,
  Plus,
  Trash as TrashIcon,
} from 'react-feather';
import { useForm } from 'react-hook-form';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { DataStoreImage } from 'components/Image';
import { Pagination } from 'components/Pagination';
import { QueryResolver } from 'components/QueryResolver';
import { createTableBuilder } from 'components/Table';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card';
import { Text } from 'components/ui/text';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { EquipmentService } from 'services/api/equipment/endpoint';
import { Equipment } from 'services/api/equipment/types';
import { paginatedParamsSchema } from 'services/api/types.shared';
import { useLocale } from 'utils/hooks/useLocale';
import { useValidatedSearchParams } from 'utils/hooks/useValidatedSearchParams';
import { toSchemaWithDefaults } from 'utils/schemas';

export default function EquipmentsIndex() {
  const { t } = useLocale();
  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title={t('Equipment')}
          // Track and manage your equipment
          description={t('equipments_page_description')}
        />
        <section className="flex flex-col gap-4 lg:flex-row lg:items-start">
          <aside className="shrink-0 lg:sticky lg:top-0 lg:w-96">
            <EquipmentDetails />
          </aside>
          <EquipmentsTable />
        </section>
      </div>
    </>
  );
}

const toTitleCase = (value: string) => {
  const words = value.split(' ').map((word) => {
    // Capitalize the first letter of the word and lowercase the rest
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  });
  // Join the words by spaces and return the result
  return words.join(' ');
};

function EquipmentDetails() {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const equipmentId = searchParams.get('equipmentId');
  const id = z.coerce.number().catch(0).parse(equipmentId);

  const query = EquipmentService.endpoints.equipment.useQuery(
    {
      equipmentId: id,
    },
    { skip: !id },
  );

  if (!id) {
    return (
      <Card className="">
        <CardContent>
          <div className="flex h-full min-h-[10rem] flex-col items-center justify-center text-center">
            <Text className="font-semibold">{t('No Equipment Selected')}</Text>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <Heading className="text-lg/7 font-semibold tracking-[-0.015em] text-zinc-950 dark:text-white sm:text-base/7">
          {t('Equipment Details')}
        </Heading>
      </CardHeader>
      <CardContent>
        <QueryResolver query={query}>
          {(equipment) => (
            <>
              <div>
                <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                  {equipment.logoDatastoreId ? (
                    <DataStoreImage
                      imageId={equipment.logoDatastoreId}
                      alt={equipment.name}
                      className="object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center space-x-2 bg-gray-50 text-gray-400">
                      <ImageIcon />
                      <span>{t('No Image')}</span>
                    </div>
                  )}
                </div>
                <div className="mt-4 space-y-3">
                  <div>
                    <h2 className="truncate text-base font-semibold leading-6 text-gray-900">
                      {equipment.name}
                    </h2>
                    <p className="truncate text-sm font-medium text-gray-500">
                      <span>{equipment.identifier}</span>
                    </p>
                  </div>
                  <div className="flex space-x-1">
                    <Badge color={equipment.available ? 'green' : 'red'}>
                      {equipment.available ? t('Available') : t('Unavailable')}
                    </Badge>
                    <Badge color="sky">
                      {t(toTitleCase(equipment.equipmentType))}
                    </Badge>
                  </div>
                  <p className="text-sm font-medium text-gray-500">
                    {equipment.description}
                  </p>
                </div>
              </div>
            </>
          )}
        </QueryResolver>
      </CardContent>
      <CardFooter className="flex justify-end space-x-4">
        <Button
          href={{
            pathname: `${id}/delete`,
            search: `?${searchParams.toString()}`,
          }}
          variant="plain"
          startEnhancer={<TrashIcon className="h-4 w-4" />}
        >
          {t('Delete')}
        </Button>
        <Button
          href={{
            pathname: `${id}/update`,
            search: `?${searchParams.toString()}`,
          }}
          startEnhancer={<EditIcon className="h-4 w-4" />}
        >
          {t('Update')}
        </Button>
      </CardFooter>
    </Card>
  );
}

const { Table, Column } = createTableBuilder<Equipment>();
const tableFormSchema = toSchemaWithDefaults(paginatedParamsSchema, {
  limit: 20,
  page: 1,
}).extend({
  sortBy: z.string().optional().default('name'),
  orderBy: z
    .nativeEnum({ asc: 'asc', desc: 'desc' } as const)
    .optional()
    .default('asc'),
});
type TableFormFields = z.infer<typeof tableFormSchema>;

function EquipmentsTable() {
  const { t } = useLocale();
  const {
    values: searchParamValues,
    searchParams,
    setSearchParams,
  } = useValidatedSearchParams(tableFormSchema);
  const query = EquipmentService.endpoints.equipments.useQuery({
    params: {
      page: searchParamValues.page,
      // sort: searchParamValues.sortBy,
      // order: searchParamValues.orderBy,
    },
  });
  const equipments = query.data?.list ?? [];
  const form = useForm<TableFormFields>({
    defaultValues: searchParamValues,
  });
  const { watch } = form;

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (!name) return;
      if (data[name]) {
        searchParams.set(name, String(data[name]));
      } else {
        searchParams.delete(name);
      }
      setSearchParams(searchParams);
    });
    return () => subscription.unsubscribe();
  }, [watch, setSearchParams, searchParams]);

  const updateSearchParamsKey = (key: string, value: string) => {
    const _searchParams = new URLSearchParams(searchParams);
    _searchParams.set(key, value);
    return _searchParams;
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
          <CardTitle>{t('Equipment')}</CardTitle>
          <div className="flex items-center justify-end divide-x lg:justify-start">
            <Button
              href={{
                pathname: 'new',
                search: `?${searchParams.toString()}`,
              }}
              startEnhancer={<Plus className="h-full w-full" />}
            >
              {t('Add Equipment')}
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <Table
          data={equipments}
          isLoading={query.isLoading || query.isFetching}
          isError={query.isError}
          isEmpty={equipments.length === 0}
        >
          <Column header={t('ID#')}>{(row) => row.id}</Column>
          <Column header={t('Name')}>
            {(row) => (
              <span className="inline-block max-w-[10rem] truncate">
                {row.name}
              </span>
            )}
          </Column>
          <Column header={t('Unique ID')}>
            {(row) => (
              <span className="inline-block max-w-[10rem] truncate">
                {row.identifier}
              </span>
            )}
          </Column>
          <Column header={t('Availability')}>
            {(row) => (
              <Badge
                className="font-sans"
                color={row.available ? 'green' : 'red'}
              >
                {row.available ? t('Available') : t('Unavailable')}
              </Badge>
            )}
          </Column>
          <Column header={t('Action')}>
            {(row) => (
              <Button
                href={{
                  pathname: window.location.pathname,
                  search: `?${updateSearchParamsKey(
                    'equipmentId',
                    row.id.toString(),
                  ).toString()}`,
                }}
                className="font-sans"
                variant="plain"
              >
                {t('View')}
              </Button>
            )}
          </Column>
        </Table>
      </CardContent>
      <CardFooter className="flex justify-end">
        <Pagination
          pageSize={query.data?.pageSize}
          totalItems={query.data?.totalElements}
        />
      </CardFooter>
    </Card>
  );
}
